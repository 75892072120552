<template>
  <div class="login-content d-flex flex-column p-10 animate__animated animate__fadeIn">
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <!--begin::Logo-->
      <div v-if="$vuetify.breakpoint.mobile" class="text-center mb-10">
        <a href="#" class="flex-column-auto">
          <img src="/media/logos/logo-with-name-black-vertical.png" width="70%" />
        </a>
      </div>

      <div class="pb-10 text-center">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h2-lg">Verifique seu endereço de e-mail</h3>
        <div>
          <p class="text-muted pt-10">
            Seu endereço de e-mail (
            <strong>{{ currentUser ? currentUser.email : '' }}</strong>
            ) ainda não foi verificado! Para começar a usar sua conta, você precisa confirmar seu endereço de e-mail
            primeiro.
          </p>
          <p>Se você não recebeu o email, clique no botão para reenviar.</p>

          <button
            class="btn btn-block btn-xs btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
            :disabled="isSubmitting"
            :class="{ 'spinner spinner-light spinner-right': isSubmitting }"
            @click="resendEmail"
          >
            Reenviar Email
          </button>
          <small>Para segurança da sua conta, precisamos verificar seu endereço de e-mail.</small>

          <hr />
          <p>Se você se registrou com endereço de e-mail errado, atualize agora.</p>
          <!--begin::Form-->
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <b-form-group label-class="font-size-h6 font-weight-bolder text-dark" label-for="email">
              <b-form-input
                class="form-control form-control-solid h-auto py-5 px-6 rounded"
                :state="validateState('email')"
                aria-describedby="input-1-live-feedback"
                :placeholder="currentUser ? currentUser.email : ''"
              />

              <b-form-invalid-feedback></b-form-invalid-feedback>
            </b-form-group>

            <button type="submit" class="btn btn-block btn-warning font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3">
              Atualizar endereço de email
            </button>
            <button
              class="btn btn-block btn-light font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
              @click="onLogout"
            >
              Sair
            </button>
          </b-form>
          <!--end::Form-->
          <!-- <router-link custom class="text-primary font-weight-bolder" :to="{ name: 'register' }">
            Cadastre-se!
          </router-link> -->
        </div>
      </div>
    </div>
    <!--end::Signin-->
  </div>
</template>

<style>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'EmailVerifyPending',
  mixins: [validationMixin],
  data() {
    return {
      isSubmitting: false,
      form: {
        email: ''
      }
    };
  },
  computed: {
    ...mapGetters(['currentCustomer', 'currentUser'])
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {},
    async resendEmail() {
      this.isSubmitting = true;

      await this.$store
        .dispatch('REGISTER_RESEND_VERIFY_EMAIL')
        .then((response) => {
          this.$swal({
            text: 'Email reenviado com sucesso!',
            icon: 'success'
          });
          this.isSubmitting = false;
        })
        .catch((error) => {
          this.$swal({
            text: error,
            icon: 'error'
          });
          this.isSubmitting = false;
        });
    },
    onLogout() {
      this.$store.dispatch('LOGOUT').then(() => this.$router.push({ name: 'login' }));
    }
  }
};
</script>
